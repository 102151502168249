import { MutationTree } from 'vuex';
import { IImageUpload } from '@/modules/image/image.interface';
import { ICompanyRoles, IUser, IUserAllowance } from '@/modules/user/user.interface';
import { IUserState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<IUserState> = {
  UPDATE_PAGINATED_USERS(state: IUserState, paginated_users: IUser[]) {
    state.paginated_users = paginated_users;
  },
  SELECTED_USER(state: IUserState, selected_user: IUser) {
    state.selected_user = selected_user;
  },
  UPDATE_SELECTED_USER(state: IUserState, updated_selected_user: IUser) {
    state.updated_selected_user = updated_selected_user;
  },
  UPDATE_SELECTED_PROFILE_IMAGE(state: IUserState, update_selected_profile_image: IImageUpload) {
    state.update_selected_profile_image = update_selected_profile_image;
  },
  APPEND_COMPANY_LIST(state: IUserState, companies: ICompanyRoles[]) {
    const companyRoles: ICompanyRoles[] = state.selected_user
      ? state.selected_user?.companyRoles || []
      : state.selected_company_list || [];
    companies.forEach((item: ICompanyRoles) => {
      companyRoles.push(item);
    });
    if (state.selected_user) {
      state.selected_user = { ...state.selected_user, companyRoles };
    }
    state.selected_company_list = companyRoles;
  },
  FILTER_COMPANY_LIST(state: IUserState, companyId: string) {
    const companyRoles: ICompanyRoles[] = state.selected_user
      ? state.selected_user?.companyRoles || []
      : state.selected_company_list || [];
    const filteredCompanyRoles: ICompanyRoles[] = companyRoles?.filter(
      (item: ICompanyRoles): boolean => item.companyId !== companyId
    );
    if (state.selected_user) {
      state.selected_user = { ...state.selected_user, companyRoles: filteredCompanyRoles };
    }
    state.selected_company_list = filteredCompanyRoles;
  },
  CLEAR_SELECTED_USER(state: IUserState) {
    state.selected_company_list = null;
    state.selected_user = null;
  },
  UPDATE_USER_ALLOWANCE(state: IUserState, update_user_allowance: IUserAllowance) {
    state.update_user_allowance = update_user_allowance;
  }
};
